import DiagramClass from "@semantical/modules/diagram/DiagramClass.ts";
import ModelRelationship from "@semantical/modules/domain-model/ModelRelationship.ts";

export default class DiagramRelationship {
    source: DiagramClass;
    target: DiagramClass;
    modelRelationship: ModelRelationship;

    constructor(modelRelationship: ModelRelationship, source: DiagramClass, target: DiagramClass) {
        this.source = source;
        this.target = target;
        this.modelRelationship = modelRelationship;
    }

    toJSON() {
        return {
            source: {
                href: this.source.modelClass.name
            },
            target: {
                href: this.target.modelClass.name
            },
            modelRelationship: {
                href: this.modelRelationship.toJSON() // TODO: Fix this
            }
        }
    }
}
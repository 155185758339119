import * as d3 from "d3";
import theme from "@semantical/theme.ts";
import React, {MouseEvent} from "react";
import DiagramClass from "@semantical/modules/diagram/DiagramClass.ts";
import DiagramEditor from "@semantical/components/diagram-editor/DiagramEditor.tsx";

function getTranslateValues(transformString: string) {
    // Extracts the translate(x,y) values from the transform string
    const match = /translate\(([^,]+),([^,]+)\)/.exec(transformString);
    return match ? {x: parseFloat(match[1]), y: parseFloat(match[2])} : {x: 0, y: 0};
}

type Props = {
    diagramClass: DiagramClass
    diagramEditor: DiagramEditor
}

type State = {}

export default class DiagramClassElement extends React.Component<Props, State> {

    svgClass: React.RefObject<SVGGElement>;

    handleMouseOver = (_e: MouseEvent) => this.highlight(true);

    handleMouseOut = (_e: MouseEvent) => {
        if (!(this.props.diagramEditor.state.selectedClass?.svgClass.current == this.svgClass.current)) {
            this.highlight(false);
        }
    }

    handleMouseClick = (_e: MouseEvent) => {
        console.debug("Clicked on target: " + this.props.diagramClass.modelClass.name);
        this.props.diagramEditor.unselectAll();
        this.props.diagramEditor.selectClass(this);
    }

    constructor(props: Props) {
        super(props);

        this.svgClass = React.createRef();
    }

    render() {
        console.debug("Rendering target: " + this.props.diagramClass.modelClass.name);

        return (
            <g ref={this.svgClass} className="diagramClass" transform={`translate(${this.props.diagramClass.x}, ${this.props.diagramClass.y})`} data-diagram-class-name={this.props.diagramClass.modelClass.name}>                <rect className="wc" width={200} height={200}
                      style={{strokeWidth: 1, stroke: theme.classBorder, fill: 'none'}}/>
                <rect className="wchover" width={200} height={200}
                      style={{strokeWidth: 0, stroke: theme.classBorderHover, strokeOpacity: 0.5, fill: 'none'}}/>
                <rect className="title" width={200} height={40} style={{fill: theme.classTitleBackground}}/>
                <rect className="props" y={40} width={200} height={160} style={{fill: theme.classPropsBackground}}/>
                <text x={200 / 2} y={15}
                      style={{textAnchor: 'middle', dominantBaseline: 'middle', fontFamily: 'Arial', fontSize: '12px'}}>
                    {`«${this.props.diagramClass.modelClass.type}»`}
                </text>
                <text x={200 / 2} y={30}
                      style={{textAnchor: 'middle', dominantBaseline: 'middle', fontFamily: 'Arial', fontSize: '16px'}}>
                    {this.props.diagramClass.modelClass.name}
                </text>
                {this.props.diagramClass.modelClass.properties.map((prop, index) => (
                    <text key={index} className="property" x={10} y={40 + 4 + 12 + (index * 16)}
                          style={{fontFamily: 'Arial', fontSize: '12px'}}>
                        {prop}
                    </text>
                ))}
            </g>
        );
    }

    handleDragStart = (event: d3.D3DragEvent<SVGGElement, unknown, unknown>) => {
        this.props.diagramEditor.handleClassDragStart(event, this)
    }

    handleDragged = (event: d3.D3DragEvent<SVGGElement, unknown, unknown>) => {
        this.props.diagramEditor.handleClassDragged(event, this);
    }

    componentDidMount() {

        d3.select(this.svgClass.current!)
            .on('mouseover', (event) => this.handleMouseOver(event))
            .on('mouseout', (event) => this.handleMouseOut(event))
            .on('click', (event) => this.handleMouseClick(event))
            .call(
                d3.drag<SVGGElement, unknown>()
                    .on('start', (event) => this.handleDragStart(event))
                    .on('drag', (event) => this.handleDragged(event)));

    }

    getPosition() {
        return getTranslateValues(d3.select(this.svgClass.current)?.attr("transform"));
    }

    highlight(enabled: boolean) {
        const svgClass = d3.select(this.svgClass.current);
        if (enabled) {
            svgClass.select("rect.wchover")
                .transition()
                .duration(300) // Duration of the animation in milliseconds
                .style("stroke-width", 10);
            svgClass.select("rect.title")
                .transition()
                .duration(300) // Duration of the animation in milliseconds
                .attr("fill", theme.classTitleBackgroundHover);
            svgClass.select("rect.props")
                .transition()
                .duration(300) // Duration of the animation in milliseconds
                .attr("fill", theme.classPropsBackgroundHover);
        } else {
            svgClass.select("rect.wchover")
                .transition()
                .duration(300)
                .style("stroke-width", 1);
            svgClass.select("rect.title")
                .transition()
                .duration(300) // Duration of the animation in milliseconds
                .attr("fill", theme.classTitleBackground);
            svgClass.select("rect.props")
                .transition()
                .duration(300) // Duration of the animation in milliseconds
                .attr("fill", theme.classPropsBackground);
        }
    }

    refresh() {

        d3.select(this.svgClass.current).selectAll("text.property").remove();
        d3.select(this.svgClass.current).selectAll("text.proposed").remove();
        this.props.diagramClass.modelClass.properties.forEach((prop, index) => {
            d3.select(this.svgClass.current).append("text")
                .attr("x", 10)
                .attr("y", 40 + 4 + 12 + (index * 16))
                .text(prop)
                .style("font-family", "Arial")
                .style("font-size", "12px");

        });
    }
}

export {DiagramClassElement};
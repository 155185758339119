import * as d3 from "d3";
import theme from "@semantical/theme.ts";
import React from "react";
import DiagramRelationship from "@semantical/modules/diagram/DiagramRelationship.ts";
import DiagramClass from "@semantical/modules/diagram/DiagramClass.ts";
import {RELATIONSHIP_TYPE} from "@semantical/modules/domain-model/ModelRelationship.ts";

export const relationshipMarkers: Map<RELATIONSHIP_TYPE, string> = new Map();
relationshipMarkers.set("COMPOSITION", "compositeDiamond");
relationshipMarkers.set("AGGREGATION", "sharedDiamond");
relationshipMarkers.set("GENERALIZATION", "generalizationArrowhead");

type Point = {
    x: number; y: number;
};

type Props = {
    relationship: DiagramRelationship;
    onRelationshipSelected?: (relationship: DiagramRelationship) => void;
    highlightedClass?: DiagramClass | null;
}

type State = {}

export class DiagramRelationshipElement extends React.Component<Props, State> {
    // private
    svgRelationship: React.RefObject<SVGGElement> = React.createRef();

    constructor(props: Props) {
        super(props);

        this.svgRelationship = React.createRef();
    }

    render() {
        console.debug("render DiagramRelationshipElement")
        return (
            <g ref={this.svgRelationship} className="diagramRelationship">
                <path className="visual" strokeWidth={1} fill="none"
                      markerStart={`url(#${relationshipMarkers.get(this.props.relationship.modelRelationship.type)})`}/>
                <path className="hit" strokeWidth={15} fill="none" stroke="white" strokeOpacity={0}/>
            </g>
        );
    }

    handleMouseOver(_e: MouseEvent) {
        console.log("mouseover");
        this.highlight();
    }

    handleMouseOut(_e: MouseEvent) {
        console.log("mouseout");
        this.refresh();
    }

    handleClick(_e: MouseEvent) {
        if (this.props.onRelationshipSelected)
            this.props.onRelationshipSelected(this.props.relationship);
    }

    componentDidMount() {
        console.debug("componentDidMount DiagramRelationshipElement");

        const hit = d3.select(this.svgRelationship.current).select("path.hit");

        hit.on("mouseover", (e) => this.handleMouseOver(e))
            .on("mouseout", (e) => this.handleMouseOut(e))
            .on("click", (e) => this.handleClick(e));

        this.refresh();
    }

    componentDidUpdate(_prevProps: Readonly<Props>, _prevState: Readonly<State>, _snapshot?: any) {
        this.refresh();
    }

    highlight() {
        d3.select(this.svgRelationship.current)
            .select("path.visual")
            .attr("stroke", theme.lineHighlight);
    }

    static GetPath(diagramClass1: DiagramClass, diagramClass2 : DiagramClass) {
        const t1 = {x: diagramClass1.x, y: diagramClass1.y};
        const t2 = {x: diagramClass2.x, y: diagramClass2.y};

        // calculate angle between t1 and t2
        const angle = Math.atan2(t2.y - t1.y, t2.x - t1.x);

        let source: Point;
        let target: Point;
        let linkGenerator: d3.Link<any, any, any>;

        if (angle > -0.8 && angle < 0.8) {
            source = {x: t1.x + 200, y: Math.max(Math.min(t2.y + 100, t1.y + 175), t1.y + 25)};
            target = {x: t2.x, y: t2.y + 100};
            linkGenerator = d3.linkHorizontal()
                .x((d: any) => d.x)
                .y((d: any) => d.y);
        } else if (angle > 0.8 && angle < 2.33) {
            source = {x: Math.max(Math.min(t2.x + 100, t1.x + 175), t1.x + 25), y: t1.y + 200};
            target = {x: t2.x + 100, y: t2.y};
            linkGenerator = d3.linkVertical()
                .x((d: any) => d.x)
                .y((d: any) => d.y);
        } else if (angle > -2.33 && angle < -0.8) {
            source = {x: Math.max(Math.min(t2.x + 100, t1.x + 175), t1.x + 25), y: t1.y};
            target = {x: t2.x + 100, y: t2.y + 200};
            linkGenerator = d3.linkVertical()
                .x((d: any) => d.x)
                .y((d: any) => d.y);
        } else {
            source = {x: t1.x, y: Math.max(Math.min(t2.y + 100, t1.y + 175), t1.y + 25)};
            target = {x: t2.x + 200, y: t2.y + 100};
            linkGenerator = d3.linkHorizontal()
                .x((d: any) => d.x)
                .y((d: any) => d.y);
        }

        return linkGenerator({source: source, target: target});
    }

    // createSvg() {
    //     this.svgRelationship = d3.select(this.diagram.svgClasses.current).append("g")
    //         .attr("target", "diagramRelationship");
    //
    //
    //     const visual = this.svgRelationship.append("path")
    //         .attr("target", "visual")
    //         .attr("stroke-width", 1)
    //         .attr("fill", "none")
    //         .attr("marker-start", `url(#${relationshipMarkers.get(this.type)})`);
    //
    //     const hit = this.svgRelationship.append("path")
    //         .attr("target", "hit")
    //         .attr("stroke-width", 15)
    //         .attr("fill", "none")
    //         .attr("stroke", "white")
    //         .attr("stroke-opacity", 0);
    //
    //     const diagramRelationship = this;
    //     hit.on("mouseover", function () {
    //         //console.log("mouseover");
    //         visual.attr("stroke", theme.lineHighlight);
    //
    //     }).on("mouseout", function () {
    //         //console.log("mouseout");
    //         diagramRelationship.refresh();
    //     }).on("click", function () {
    //         diagramRelationship.diagram.selectRelationship(diagramRelationship);
    //     });
    //
    //     return this.svgRelationship;
    // }

    refresh() {
        //console.log({source, target});
        const source = this.props.relationship.source;
        const target = this.props.relationship.target;

        d3.select(this.svgRelationship.current).raise()
            .attr("d", DiagramRelationshipElement.GetPath(source, target))
            .attr("stroke", theme.line)
            .attr("stroke-width", 1);

        // select sub element of svgRelationship with target "visual"
        const d = DiagramRelationshipElement.GetPath(source, target);
        const visual = d3.select(this.svgRelationship.current).select("path.visual")
            .attr("d", d)
            .attr("stroke", theme.line)
            .attr("stroke-width", 1);

        // const hit = this.svgRelationship.select("path.hit")
        //     .attr("d", d)

        // Check if either target is in the selected classes array
        if (this.props.highlightedClass == source || d3.select(this.svgRelationship.current).classed("selected")) {
            // Change the stroke color to highlight the line
            visual.attr("stroke", theme.lineHighlight);
        } else {
            // Set the stroke color back to the default color
            visual.attr("stroke", theme.line);
        }


    }
}

export default DiagramRelationshipElement;
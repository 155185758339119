import N3, {DataFactory} from 'n3';
import {Concept} from "./Concept.ts";
import namedNode = DataFactory.namedNode;
import {TVocabulary} from "@semantical/components/chat";

const rdf = {
    type: 'http://www.w3.org/1999/02/22-rdf-syntax-ns#type'
}, skos = {
    namespace: 'http://www.w3.org/2004/02/skos/core#',
    Concept: 'http://www.w3.org/2004/02/skos/core#Concept',
    prefLabel: 'http://www.w3.org/2004/02/skos/core#prefLabel',
    broader: 'http://www.w3.org/2004/02/skos/core#broader',
    related: 'http://www.w3.org/2004/02/skos/core#related',
    narrower: 'http://www.w3.org/2004/02/skos/core#narrower',
    definition: 'http://www.w3.org/2004/02/skos/core#definition'
};

export default class Vocabulary {
    readonly store: N3.Store;

    constructor(store: N3.Store) {
        this.store = store;
    }

    toJSON() : TVocabulary {
        return {
            concepts: this.getConcepts().map(concept => concept.toJSON())
        };
    }

    static fromTurtle(turtleData: string) : Promise<Vocabulary> {
        return new Promise((resolve, reject) => {
            const store = new N3.Store();
            const parser = new N3.Parser();

            parser.parse(turtleData, (error, quad, _prefixes) => {
                if (error) {
                    reject(error);
                } else if (quad) {
                    store.addQuad(quad);
                } else {
                    resolve(new Vocabulary(store));
                }
            });
        });
    }

    getConcept(uri: string): Concept {
        //const skosConcept = this.store.getQuads(uri, rdf.type, skos.Concept, null)[0];
        const conceptLabel = this.store.getQuads(uri, skos.prefLabel, null, null)[0];
        const conceptName = conceptLabel.object.value;
        const broaderRelations = this.store.getQuads(uri, skos.broader, null, null);
        const narrowerRelations = this.store.getQuads(uri, skos.narrower, null, null);
        const definition = this.store.getQuads(uri, skos.definition, null, null);

        // deduce narrower  relations based on broader relations
        const _broaderRelations = this.store.getQuads(null, skos.broader, uri, null);
        const narrowers = narrowerRelations.map(narrowerRelation => narrowerRelation.object.value);
        const _narrowers = _broaderRelations.map(broaderRelation => broaderRelation.subject.value);
        // join narrowers and _narrowers
        _narrowers.forEach(narrower => {
            if (!narrowers.includes(narrower)) {
                narrowers.push(narrower);
            }
        });

        return new Concept(uri, conceptName, broaderRelations.map(broaderRelation => broaderRelation.object.value), narrowers, definition[0] ? definition[0].object.value : "No definition provided");
    }

    getConcepts(): Concept[] {
        const skosConcepts = this.store.getQuads(null, rdf.type, skos.Concept, null);
        return skosConcepts.map(skosConcept => this.getConcept(skosConcept.subject.value));
    }

    addConcept(concept: Concept) {
        this.store.addQuad(namedNode(concept.uri), namedNode(rdf.type), namedNode(skos.Concept));
        this.store.addQuad(namedNode(concept.uri), namedNode(skos.prefLabel), DataFactory.literal(concept.prefLabel, 'en'));
        this.store.addQuad(namedNode(concept.uri), namedNode(skos.definition), DataFactory.literal(concept.definition, 'en'));
        concept.broader.forEach(broader => {
            this.store.addQuad(namedNode(concept.uri), namedNode(skos.broader), namedNode(broader));
        });
        concept.narrower.forEach(narrower => {
            this.store.addQuad(namedNode(concept.uri), namedNode(skos.narrower), namedNode(narrower));
        });
    }

    updateConceptDefinition(conceptUri: string, newDefinition: string) {
        const definitionQuads = this.store.getQuads(namedNode(conceptUri), namedNode(skos.definition), null, null);
        definitionQuads.forEach(quad => {
            this.store.removeQuad(quad);
        });
        this.store.addQuad(namedNode(conceptUri), namedNode(skos.definition), DataFactory.literal(newDefinition, 'en'));
    }

    getD3Graph() {
        const nodes: { id: string, name: string, group: number }[] = [];
        const links: { source: string, target: string, value: string }[] = [];
        const skosConcepts = this.store.getQuads(null, rdf.type, skos.Concept, null);
        skosConcepts.forEach(skosConcept => {
            const conceptUri = skosConcept.subject.value;
            const conceptLabel = this.store.getQuads(skosConcept.subject, skos.prefLabel, null, null)[0];
            const conceptName = conceptLabel.object.value;
            nodes.push({
                "id": conceptUri, "name": conceptName, "group": 1
            });

            const broaderRelations = this.store.getQuads(skosConcept.subject, skos.broader, null, null);
            broaderRelations.forEach(broaderRelation => {
                links.push({
                    "source": conceptUri, "target": broaderRelation.object.value, "value": "broader"
                });
            });

            const narrowerRelations = this.store.getQuads(skosConcept.subject, skos.narrower, null, null);
            narrowerRelations.forEach(narrowerRelation => {
                links.push({
                    "source": conceptUri, "target": narrowerRelation.object.value, "value": "narrower"
                });
            });
        });

        return {
            "nodes": nodes, "links": links
        };
    }
}

export {Vocabulary};
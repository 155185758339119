import ModelClass from "@semantical/modules/domain-model/ModelClass.ts";
import ModelRelationship from "@semantical/modules/domain-model/ModelRelationship.ts";
import Vocabulary from "@semantical/modules/vocabulary/Vocabulary.ts";

export default class DomainModel {
    classes: Map<string, ModelClass> = new Map<string, ModelClass>();
    name: string;

    constructor(name: string) {
        this.name = name;
    }

    toJSON() {
        return {
            name: this.name,
            classes: Array.from(this.classes.values()).map((aClass) => aClass.toJSON())
        }
    }

    static fromJSON(json: any, vocabulary: Vocabulary) {
        const domainModel = new DomainModel(json.name);
        json.classes.forEach((aClass: any) => {
            domainModel.addClass(ModelClass.fromJSON(aClass, vocabulary));
        });
        return domainModel;
    }

    addClass(aClass: ModelClass) {
        this.classes.set(aClass.name, aClass);
    }

    addRelationship(modelRelationship: ModelRelationship) {
        const source = this.classes.get(modelRelationship.sourceEnd.modelClass.name);
        source?.addRelationship(modelRelationship);
    }

}

export {DomainModel};
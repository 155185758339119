import Concept from "@semantical/modules/vocabulary/Concept.ts";
import ModelRelationship from "@semantical/modules/domain-model/ModelRelationship.ts";
import Vocabulary from "@semantical/modules/vocabulary/Vocabulary.ts";
import {TConceptRef, TModelClass} from "@semantical/components/chat";

const classes = [{
    "name": "Car",
    "properties": [],
    "proposedProperties": ["+ year: String", "+ model: String", "+ brand: String"],
    "type": "Entity",
    "definition": "no definition provided",
    "proposedDefinitions": [{
        "definition": "a vehicle that has four wheels and an engine and that is used for carrying passengers on roads",
        "source": "Britannica",
        "proposedRelationships": [{
            "class": {
                "name": "Wheel",
                "properties": [],
                "proposedProperties": ["+ year: String", "+ model: String"],
                "type": "ValueObject",
                "definition": "no definition provided",
                "proposedDefinitions": [{
                    "definition": "a circular object connected at the centre to a bar, used for making vehicles or parts of machines move",
                    "source": "Cambridge Dictionary",
                    "proposedRelationships": []
                }],
                "relationships": [],
                "proposedRelationships": []
            }, "role": "has", "cardinality": "4", "type": "Composition"
        }]
    }, {
        "definition": "any of the separate parts of a train in which the passengers sit",
        "source": "Britannica",
        "proposedRelationships": []
    }, {
        "definition": "Abbreviation for capital adequacy ratio",
        "source": "Cambridge Dictionary",
        "proposedRelationships": []
    }, {
        "definition": "Abbreviation for civil air regulations", "source": "Merriam-Webster", "proposedRelationships": []
    }, {
        "definition": "Abbreviation for Central African Republic", "source": "Wikipedia", "proposedRelationships": []
    }],
    "relationships": [{
        "class": {
            "name": "Engine",
            "properties": [],
            "proposedProperties": ["+ fuelType: String", "+ horsePower: Integer"],
            "type": "ValueObject",
            "definition": "no definition provided",
            "proposedDefinitions": [],
            "relationships": [],
            "proposedRelationships": []
        }, "role": "has", "cardinality": "1", "type": "Composition"
    }/*,
            {
                "target": {
                    "name": "Passenger",
                    "properties": [
                        "+ name: String",
                        "+ age: Integer"
                    ]
                },
                "role": "carries",
                "cardinality": "0..*",
                "type": "Aggregation"
            }*/],
    "proposedRelationships": []

}, {
    "name": "Engine",
    "properties": [],
    "proposedProperties": ["+ fuelType: String", "+ horsePower: Integer"],
    "type": "ValueObject",
    "definition": "no definition provided",
    "proposedDefinitions": [{
        "definition": "a machine that changes energy (such as heat from burning fuel) into mechanical motion",
        "source": "Britannica Dictionary",
        "proposedRelationships": []
    }, {
        "definition": "the vehicle that pulls a train", "source": "Britannica Dictionary", "proposedRelationships": []
    }, {
        "definition": "something that produces a particular and usually desirable result",
        "source": "Britannica Dictionary",
        "proposedRelationships": []
    }],
    "relationships": [],
    "proposedRelationships": []
}];

export default class ModelClass {
    name: string;
    type: string;
    properties: string[] = [];
    proposedProperties: string[] = [];
    definition: string = "";
    proposedDefinitions: any = [];
    relationships: ModelRelationship[] = [];
    proposedRelationships: any = [];
    concept: Concept | null = null;
    ontoUmlStereotype: string | undefined = undefined;

    constructor(type: string, name: string) {
        this.type = type;
        this.name = name;
    }

    static fromJSON(json: any, vocabulary: Vocabulary) {
        const modelClass = new ModelClass(json.type, json.name);
        modelClass.properties = json.properties;
        modelClass.proposedProperties = json.proposedProperties;
        modelClass.definition = json.definition;
        modelClass.proposedDefinitions = json.proposedDefinitions;
        modelClass.relationships = json.relationships.map((relationship: any) => ModelRelationship.fromJSON(relationship, vocabulary));
        modelClass.proposedRelationships = json.proposedRelationships;
        modelClass.concept = json.concept ? vocabulary.getConcept(json.concept.href) : null
        return modelClass;
    }

    toJSON(): TModelClass {
        return {
            name: this.name,
            type: this.type,
            properties: this.properties.map((property) => {
                // convert "+ fuelType: String" to {name: "fuelType", type: "String", cardinality: "1"} using a regexp
                const matches = property.match(/(\+|-)?\s*(\w+)\s*:\s*(\w+)/);
                return {
                    name: matches![2],
                    type: matches![3],
                    cardinality: "1"
                }
            }),
            definition: (this.definition) ? this.definition : (this.concept) ? this.concept.definition : undefined,
            relationships: this.relationships.map((relationship) => relationship.toJSON()),
            concept: (this.concept) ? {
                href: this.concept.uri
            } as TConceptRef : undefined
        }
    }

    addRelationship(modelRelationship: ModelRelationship) {
        this.relationships.push(modelRelationship);
    }
}

console.table(classes);

export {classes, ModelClass};
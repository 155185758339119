import * as d3 from "d3";
import {Selection} from "d3";
import {Concept, Vocabulary} from "@semantical/modules/vocabulary";
import React from "react";

type Props = {
    vocabulary: Vocabulary,
    onConceptSelected: (concept: Concept) => void,
}

type State = {}

class VisualVocabulary extends React.Component<Props, State> {
    svg: React.RefObject<SVGSVGElement>;
    linkLabels: Selection<SVGTextElement, any, any, any> | null = null;
    links: Selection<SVGLineElement, any, any, any> | null = null;
    nodes: Selection<SVGGElement, any, any, any> | null = null;
    simulation: d3.Simulation<any, any> | null = null;

    graph: any;

    constructor(props: Props) {
        super(props);

        this.svg = React.createRef();
    }

    render() {
        console.debug("VisualVocabulary render");
        return (
            <div id="visualVocabulary">
                <svg ref={this.svg} width="100%" height="100%"></svg>
            </div>

        );
    }

    componentDidMount() {
        console.debug("VisualVocabulary mounted");

        const svg = d3.select(this.svg.current);

        const rect = svg.node()!.getBoundingClientRect();

        const width = rect.width;
        const height = rect.height;

        const defs = svg.append("defs");

        defs.append("marker")
            .attr("id", "end")
            .attr("viewBox", "0 -5 10 10")
            .attr("refX", 0)    // Controls the distance of the marker from the node
            .attr("refY", 0)
            .attr("markerWidth", 10)
            .attr("markerHeight", 10)
            .attr("orient", "auto")
            .append("path")
            .attr("d", "M0,-5L10,0L0,5")
            .attr("fill", "white");

        this.graph = this.props.vocabulary.getD3Graph();

        this.linkLabels = this.createLinkLabels();

        this.links = this.createLinks();

        // Create a drag handler and append it to the node object instead
        const drag_handler = d3.drag<SVGGElement, any>()
            .on("start", this.dragStarted)
            .on("drag", this.dragged.bind(this))
            .on("end", this.dragEnded.bind(this));

        this.nodes = this.createNodes(this.props.onConceptSelected, drag_handler);

        this.simulation = d3.forceSimulation()
            .force("link", d3.forceLink().id(function (d: any) {
                return d.id;
            }))
            .force("charge", d3.forceManyBody().strength(-5000))
            .force("center", d3.forceCenter(width / 2, height / 2));

        this.simulation
            .nodes(this.graph.nodes)
            .on("tick", () => this.update());

        (this.simulation.force("link") as d3.ForceLink<any, any>).links(this.graph.links);

    }

    createNodes(onConceptSelected: (concept: Concept) => void, drag_handler: d3.DragBehavior<SVGGElement, any, any>): d3.Selection<SVGGElement, any, any, any> {
        const nodes: d3.Selection<SVGGElement, any, any, any> = d3.select(this.svg.current).append("g")
            .attr("class", "nodes")
            .selectAll("g")
            .data(this.graph.nodes)
            .enter().append("g")
            .on("click", (_event, d: any) => onConceptSelected(this.props.vocabulary.getConcept(d.id)));


        const color = d3.scaleOrdinal(d3.schemeCategory10);

        nodes.append("circle")
            .attr("r", 5)
            .attr("fill", function (d: any) {
                return color(String(d.group));
            });

        nodes.append("text")
            .text(function (d: any) {
                return d.name;
            })
            .attr('x', 17)
            .attr('y', 7)
            .attr("font-weight", "bold")
            .attr("fill", "rgb(120,160,210)");

        nodes.append("title")
            .text(function (d: any) {
                return d.name;
            });

        drag_handler(nodes);

        return nodes;
    }

    createLinks() {
        return d3.select(this.svg.current).append("g")
            .attr("class", "links")
            .selectAll("line")
            .data(this.graph.links)
            .enter().append("line")
            //.attr("stroke-width", function(d) { return Math.sqrt(d.value); })
            .attr("marker-end", "url(#end)");
    }

    createLinkLabels() {
        return d3.select(this.svg.current).append("g")
            .attr("class", "link-labels")
            .selectAll("text")
            .data(this.graph.links)
            .enter().append("text")
            .attr("fill", "gray")
            .text(function (d: any) {
                return String(d.value);
            });  // Assuming each link has a 'role' property
    }

    update() {
        this.links!
            .attr("x1", (d: any) => {
                const dx = d.target.x - d.source.x;
                const dy = d.target.y - d.source.y;
                const r = Math.sqrt(dx * dx + dy * dy);
                const offsetX = (dx * 25) / r; // Offset by 5 units; adjust as needed
                return d.source.x + offsetX;
            })
            .attr("y1", function (d: any) {
                const dx = d.target.x - d.source.x;
                const dy = d.target.y - d.source.y;
                const r = Math.sqrt(dx * dx + dy * dy);
                const offsetY = (dy * 25) / r; // Offset by 5 units; adjust as needed
                return d.source.y + offsetY;
            })
            .attr("x2", function (d: any) {
                const dx = d.target.x - d.source.x;
                const dy = d.target.y - d.source.y;
                const r = Math.sqrt(dx * dx + dy * dy);
                const offsetX = (dx * 25) / r; // Make the offset larger for the target end
                return d.target.x - offsetX;
            })
            .attr("y2", function (d: any) {
                const dx = d.target.x - d.source.x;
                const dy = d.target.y - d.source.y;
                const r = Math.sqrt(dx * dx + dy * dy);
                const offsetY = (dy * 25) / r; // Make the offset larger for the target end
                return d.target.y - offsetY;
            });

        this.nodes!
            .attr("transform", ({x, y}: any) => `translate(${x},${y})`);

        this.linkLabels!
            .attr("x", function (d: any) {
                return (d.source.x + d.target.x) / 2;
            })
            .attr("y", function (d: any) {
                return (d.source.y + d.target.y) / 2;
            });
    }

    dragStarted = (event: d3.D3DragEvent<any, MyDatum, any>, d: MyDatum) => {
        if (!event.active) this.simulation!.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
    }

    dragged(event: d3.D3DragEvent<any, any, any>, d: MyDatum) {
        d.fx = event.x;
        d.fy = event.y;
    }

    dragEnded(event: d3.D3DragEvent<any, any, any>, d: MyDatum) {
        if (!event.active) this.simulation!.alphaTarget(0);
        d.fx = null;
        d.fy = null;
    }
}

type MyDatum = {
    fx: number | null
    fy: number | null
    x: number
    y: number
};

export {VisualVocabulary};


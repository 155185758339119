const themes = {
    "dark": {
        "background": "rgb(32, 30, 38)",
        "grid": "rgb(38, 40, 43)",
        "classTitleBackground": "rgb(120,160,210)",
        "classPropsBackground": "rgb(180,210,255)",
        "classTitleBackgroundHover": "rgb(170,210,255)",
        "classPropsBackgroundHover": "rgb(210,240,255)",
        "classBorder": "black",
        "lineDiamondFill": "white",
        "lineHighlight": "cyan",
        "line": "white",
        "classBorderHover": "cyan",
        "classShadow": "black"
    },
    "light": {
        "background": "rgb(230,230,230)",
        "grid": "rgb(220,220,220)",
        "classTitleBackground": "lightblue",
        "classPropsBackground": "lightgreen",
        "classTitleBackgroundHover": "cyan",
        "classPropsBackgroundHover": "rgb(150,255,150)",
        "classBorder": "black",
        "lineDiamondFill": "black",
        "lineHighlight": "green",
        "line": "black",
        "classBorderHover": "green",
        "classShadow": "rgb(220,220,220)"
    }

}

const theme = themes.dark;

export { themes, theme };
export default theme;
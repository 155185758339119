import * as d3 from "d3";
import DiagramClassElement from "./DiagramClassElement.tsx";
import {DiagramRelationshipElement} from "./DiagramRelationshipElement.tsx";
import theme from "@semantical/theme.ts";
import {ModelClass} from "@semantical/modules/domain-model";
import React from "react";
import Tooltips from "@semantical/components/diagram-editor/Tooltips.tsx";
import Diagram from "@semantical/modules/diagram/Diagram.ts";
import DiagramClass from "@semantical/modules/diagram/DiagramClass.ts";
import DiagramRelationship from "@semantical/modules/diagram/DiagramRelationship.ts";
import ModelRelationship, {RELATIONSHIP_TYPE} from "@semantical/modules/domain-model/ModelRelationship.ts";

function snapToGrid(value: number, GRID_SIZE: number) {
    return Math.round(value / GRID_SIZE) * GRID_SIZE;
}

function getTranslateValues(transformString: string) {
    // Extracts the translate(x,y) values from the transform string
    const match = /translate\(([^,]+),([^,]+)\)/.exec(transformString);
    return match ? {x: parseFloat(match[1]), y: parseFloat(match[2])} : {x: 0, y: 0};
}

let dx0 = 0, dy0 = 0;

type Props = {
    onClassSelected: { (diagramClass: DiagramClass | null): void }
    gridSize: number
    diagram: Diagram
    onClassAdded: { (diagramClass: DiagramClass): void }
    onClassMoved: { (diagramClass: DiagramClass, x:number, y:number): void }
    onRelationshipAdded: { (diagramRelationship: DiagramRelationship): void }
}

type State = {
    "selectedClass": DiagramClassElement | null
}

export default class DiagramEditor extends React.Component<Props, State> {
    "svg": React.RefObject<SVGSVGElement>;
    "svgViewport": React.RefObject<SVGGElement>
    "svgClasses": React.RefObject<SVGGElement>
    "dragActive" = false
    "dragActiveElement": d3.Selection<SVGRectElement, any, any, any> | null = null
    "dragActiveModel": ModelClass | null = null
    "relationships": DiagramRelationshipElement[] = []
    "selectedRelationships": DiagramRelationship[] = []
    "onUnselectAllClasses" = null

    state: State = {
        "selectedClass": null
    }

    getDiagram() {
        return this.props.diagram;
    }

    constructor(props: Props) {
        super(props);

        this.svg = React.createRef();
        this.svgClasses = React.createRef();
        this.svgViewport = React.createRef();
    }

    componentDidMount() {
        console.debug("DiagramEditor mounted");

        if (this.svg.current == null) return; // svg ref is not set

        const diagramEditor = this;
        const svg = d3.select(this.svg.current);

        const zoom: d3.ZoomBehavior<SVGSVGElement, any> = d3.zoom<SVGSVGElement, any>().filter(() => !diagramEditor.dragActive)
            .on("zoom", (event) => this.handleViewportZoom(event));

        svg
            .call(zoom)
            .on("click", (event) => this.handleDiagramClick(event))
            .on("dblclick.zoom", null) // disable double click zoom
            .on("dblclick", () => svg.call(zoom.transform as any, d3.zoomIdentity))
            .on("mousemove", (event) => this.handleDiagramMouseMove(event));


        // Attach resize event listener
        window.addEventListener("resize", () => this.onResize());

    }

    render() {
        console.debug("DiagramEditor render");

        return (
            <div id="diagram">
                <svg ref={this.svg} width="100%" height="100%">
                    <g ref={this.svgViewport} className={"viewport"}>
                        <rect id="grid" width="100000" height="100000" x="-50000" y="-50000"
                              fill="url(#gridPattern)"></rect>
                        <g ref={this.svgClasses} className={"classes"}>
                            {this.props.diagram.classes.map((c, index) => (
                                <DiagramClassElement key={index} diagramEditor={this} diagramClass={c}/>
                            ))}
                            {this.props.diagram.relationships.map((r, index) => (
                                <DiagramRelationshipElement
                                    key={index}
                                    relationship={r}
                                    onRelationshipSelected={() => this.selectRelationship(r)}
                                />
                            ))}
                        </g>
                        {(this.state.selectedClass) ?
                            <Tooltips diagram={this}
                                      addRelationship={(type, source, target) => this.addRelationship(type, source, target)}
                                      forClass={this.state.selectedClass}
                            /> : null
                        }
                    </g>
                    <defs>
                        <pattern id="gridPattern" width="25" height="25" patternUnits="userSpaceOnUse" x="0" y="0">
                            <rect width={this.props.gridSize} height={this.props.gridSize} fill="transparent"
                                  stroke="rgb(38, 40, 43)"></rect>
                        </pattern>
                        <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
                            <feGaussianBlur in="SourceAlpha" stdDeviation="10" result="blur"></feGaussianBlur>
                            <feFlood color="black" result="color"></feFlood>
                            <feComposite in="color" in2="blur" operator="in" result="shadow"></feComposite>
                            <feOffset in="feComposite" dx="15" dy="15" result="offsetBlur"></feOffset>
                            <feMerge>
                                <feMergeNode in="offsetBlur"></feMergeNode>
                                <feMergeNode in="SourceGraphic"></feMergeNode>
                            </feMerge>
                        </filter>
                        <marker id="arrowhead" viewBox="0 -5 10 10" refX="10" refY="0" markerWidth="6" markerHeight="6"
                                orient="auto">
                            <path d="M0,-5L10,0L0,5" fill="white" stroke="black"></path>
                        </marker>
                        <marker id="compositeDiamond" viewBox="0 -5 10 10" refX="0" refY="0" markerWidth="15"
                                markerHeight="15" orient="auto">
                            <path d="M0,0L5,5L10,0L5,-5Z" fill="white"></path>
                        </marker>
                        <marker id="generalizationArrowhead" viewBox="0 -5 10 10" refX="0" refY="0" markerWidth="15"
                                markerHeight="15" orient="auto">
                            <path d="M0,0L10,-5L10,5L0,0Z" stroke="white"></path>
                        </marker>
                        <marker id="sharedDiamond" viewBox="0 -5 10 10" refX="0" refY="0" markerWidth="15"
                                markerHeight="15" orient="auto">
                            <path d="M0,0L5,5L10,0L5,-5Z" stroke="white"></path>
                        </marker>
                    </defs>
                </svg>
            </div>
        )
    }

    stopDrag = () => {
        if (this.dragActiveElement) {
            this.dragActive = false;
            this.dragActiveElement.remove();
            this.dragActiveElement = null;
            this.dragActiveModel = null;
        }
    }

    handleViewportZoom = (event: d3.D3ZoomEvent<SVGGElement, any>) => {
        d3.select(this.svgViewport.current).attr("transform", event.transform.toString());
        // move grid rect to the opposite direction of the zoom
        const scale = event.transform.k;
        const tx = event.transform.x;
        const ty = event.transform.y;
        const x = -tx / scale;
        const y = -ty / scale;
        d3.select("#grid")
            .attr("x", -50000 + x)
            .attr("y", -50000 + y);
    };

    handleDiagramClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        if (this.dragActive) {
            const [x, y] = d3.pointer(event, this.svgViewport.current);
            const model = this.dragActiveModel!;
            this.addDiagramClass({x: x - 100, y: y - 50}, model);
            this.stopDrag();
        } else {
            let target = event.target as HTMLElement | null;
            let isClass = false;
            while (target) {
                if (d3.select(target).classed("classes")) {
                    isClass = true;
                    break;
                }
                target = target.parentElement;
            }
            if (!isClass) this.unselectAll();
        }
    };

    handleDiagramMouseMove = (event: any) => {
        if (this.dragActive) {
            //console.log({event});
            // move dragActiveElement to cursor position
            const [x, y] = d3.pointer(event, d3.select(this.svgViewport.current).node());
            this.dragActiveElement!.attr("transform", `translate(${x - 100},${y - 50})`);
        }
    }

    handleClassDragStart({x, y}: d3.D3DragEvent<any, any, any>, diagramClass: DiagramClassElement) {
        d3.select(diagramClass.svgClass.current).interrupt();

        const translate = getTranslateValues(d3.select(diagramClass.svgClass.current).attr('transform'));

        dx0 = x - translate.x;
        dy0 = y - translate.y;
    }

    handleClassDragged({x, y, sourceEvent}: d3.D3DragEvent<any, any, any>, diagramClassElement: DiagramClassElement) {
        //const svgClass = diagramClassElement.svgClass;
        // Snap the x and y to the grid
        const dx = sourceEvent.shiftKey ? x - dx0 : snapToGrid(x - dx0, this.props.gridSize);
        const dy = sourceEvent.shiftKey ? y - dy0 : snapToGrid(y - dy0, this.props.gridSize);

        //d3.select(svgClass.current).attr("transform", `translate(${dx},${dy})`);

        this.props.onClassMoved(diagramClassElement.props.diagramClass, dx, dy);

        if (this.state.selectedClass == diagramClassElement)
            this.setState({"selectedClass": this.state.selectedClass});

        // Update the relationships
        diagramClassElement.props.diagramEditor.refreshRelationships();
    }


    findClassByTarget(target: HTMLElement): DiagramClass | undefined {

        let el: HTMLElement | null = target;

        // detect which diagram-editor target is under the cursor
        while (el) {
            if (d3.select(el).classed("diagramClass")) {
                break;
            }
            el = el.parentElement;
        }
        if (el) {
            // find in classes the corresponding target
            return this.props.diagram.classes.find(c => c.modelClass.name === el.dataset.diagramClassName);
        }
        return undefined;
    }

    onResize() {

    }

    addDiagramClass(point: { x: number, y: number }, aClass: ModelClass) {
        const diagramClass = new DiagramClass(aClass, point.x, point.y, 200, 100);
        //this.classes.push(new DiagramClassElement(this, diagramClass));
        this.props.onClassAdded(diagramClass);
        return diagramClass;
    }

    addRelationship(type: RELATIONSHIP_TYPE, diagramClass1: DiagramClass, diagramClass2: DiagramClass) {
        console.log("Adding relationship: " + type + " between " + diagramClass1.modelClass.name + " and " + diagramClass2.modelClass.name)

        let name;
        if (type === "GENERALIZATION") {
            name = "is a";
        } else if (type === "AGGREGATION") {
            name = "encompasses";
        } else if (type === "COMPOSITION") {
            name = "is a";
        }

        let modelRelationship;
        if (type === "GENERALIZATION") {
            modelRelationship = new ModelRelationship(
                name,
                type,
                {modelClass: diagramClass2.modelClass},
                {modelClass: diagramClass1.modelClass});
        } else {
            modelRelationship = new ModelRelationship(
                name,
                type,
                {modelClass: diagramClass1.modelClass},
                {modelClass: diagramClass2.modelClass, cardinality: "*"});
        }

        const diagramRelationship = new DiagramRelationship(modelRelationship, diagramClass1, diagramClass2);
        this.props.onRelationshipAdded(diagramRelationship);

        //return diagramRelationship;
    }

    selectClass(diagramClassElement: DiagramClassElement) {
        console.log({diagramClass: diagramClassElement, "this": this});
        this.setState({
            selectedClass: diagramClassElement
        });
        diagramClassElement.highlight(true);
        this.refreshRelationships();
        this.props.onClassSelected(diagramClassElement.props.diagramClass);
    }

    unselectAll() {
        this.unselectAllClasses();
        this.unselectAllRelationships();
    }

    unselectAllRelationships() {
        this.selectedRelationships.forEach(_r => {
            //r.svgRelationship.classed("selected", false);
            //r.refresh();
        });
        this.selectedRelationships = [];
    }

    unselectAllClasses() {
        this.state.selectedClass?.highlight(false);
        this.setState({selectedClass: null});
        this.props.onClassSelected(null);
        this.refreshRelationships();
    }

    refreshClasses() {
        //this.classes.forEach(c => c.refresh());
    }

    refreshRelationships() {
        this.relationships.forEach(_r => {
            //r.refresh();
        });
    }

    selectRelationship(diagramRelationship: DiagramRelationship) {
        this.selectedRelationships.push(diagramRelationship);
        //diagramRelationship.svgRelationship.classed("selected", true);
        //diagramRelationship.refresh();
    }

    deleteSelectedClasses() {
        console.log("Delete selected");
        // const selectedClasses = (this.state.selectedClass) ? [this.state.selectedClass] : [];
        // selectedClasses.forEach(c => {
        //     console.log("Delete target: " + c.props.diagramClass.modelClass.name);
        //     d3.select(c.svgClass.current).remove();
        //     this.classes = this.classes.filter(cl => cl !== c);
        //     this.relationships.find(r => r.source === c || r.target === c)?.svgRelationship.remove();
        //     this.relationships = this.relationships.filter(r => r.source !== c && r.target !== c);
        // });
        // this.setState({selectedClass: null});
    }

    createClassPlaceholder(): d3.Selection<SVGRectElement, any, any, any> {
        return d3.select(this.svgClasses.current).append("rect")
            .attr("width", 200)
            .attr("height", 100)
            .attr("stroke", theme.classBorderHover)
            .attr("stroke-width", 2)
            .attr("fill", "none")
            .style("pointer-events", "none");
    }
}

export {DiagramEditor}
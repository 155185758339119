import {TConcept} from "@semantical/components/chat";

export default class Concept {
    uri: string; // A string that likely represents a unique identifier for the concept, often a URI in semantic web contexts.
    prefLabel: string; // A string for the preferred label, which is the primary name by which the concept is known.
    broader: string[]; // An array of strings representing URIs of broader concepts, which this concept is a subset of.
    narrower: string[]; // An array of strings for narrower concepts, indicating more specific concepts derived from this one.
    definition: string; // A string providing the definition of the concept, explaining what it encompasses.

    constructor(uri: string, prefLabel: string, broader: string[], narrower: string[], definition: string) {
        this.uri = uri;
        this.prefLabel = prefLabel;
        this.broader = broader;
        this.narrower = narrower;
        this.definition = definition;
    }

    toJSON(): TConcept  {
        return {
            uri: this.uri,
            prefLabel: this.prefLabel,
            broader: this.broader.map(broader => ({href: broader})),
            narrower: this.narrower.map(narrower => ({href: narrower})),
            definition: this.definition
        };
    }
}

export {Concept};
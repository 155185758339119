const OPENAI_API_KEY = 'sk-test-Bd9QPEIrGkEXrXPmvYjUT3BlbkFJLCcJbYhh30kihRpbrdSv';

const OPENAI_MODELS = {
    GPT4_TURBO: 'gpt-4-turbo-preview',
    GPT3_TURBO: 'gpt-3.5-turbo'
} as const;

export type Message = {
    role: string,
    content: string
};

export default class OpenAI {

    messages: Message[];

    constructor(systemInstructions: string) {
        this.messages = [{role: 'system', content: systemInstructions}]
    }

    doChat(query: string, callback: (response: string) => void) {

        this.messages = [...this.messages, {role: 'user', content: query}]

        fetch('https://api.openai.com/v1/chat/completions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${OPENAI_API_KEY}`
            },
            body: JSON.stringify({
                'model': OPENAI_MODELS.GPT4_TURBO,
                'messages': this.messages,
                'response_format': {"type": "json_object"}
            })
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                this.messages = [...this.messages, data.choices[0].message]
                callback(data.choices[0].message.content);
            })
            .catch((error) => console.error('Error:', error));

    }
}
import {App} from '@semantical/App.tsx';
//import vehicleTtl from '@semantical/assets/vehicle.ttl?raw';

import {createRoot} from 'react-dom/client';
import {StrictMode} from "react";
import Vocabulary from "@semantical/modules/vocabulary/Vocabulary.ts";
import N3 from "n3";

const vocabulary = new Vocabulary(new N3.Store());

const root = createRoot(document.getElementById('appContainer')!);
root.render(
        <StrictMode>
            <App
                vocabulary={vocabulary}
            />
        </StrictMode>
);



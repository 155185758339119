import * as d3 from 'd3';

import {theme} from '@semantical/theme';
import {VisualVocabulary} from '@semantical/components/visual-vocabulary';

import {ModelClass} from "@semantical/modules/domain-model";
import {DiagramEditor} from "@semantical/components/diagram-editor";
import {Concept, Vocabulary} from "@semantical/modules/vocabulary";
import Properties from "@semantical/components/Properties.tsx";
import React from "react";
import Diagram from "@semantical/modules/diagram/Diagram.ts";
import DiagramClass from "@semantical/modules/diagram/DiagramClass.ts";
import DiagramRelationship from "@semantical/modules/diagram/DiagramRelationship.ts";
import DomainModel from "@semantical/modules/domain-model/DomainModel.ts";
import {Chat, Advice, ConceptAdvice, NewConceptAction} from "@semantical/components/chat";

type Props = {
    vocabulary: Vocabulary;
}

type State = {
    selectedClass: ModelClass | null;
    showDiagram: boolean;
    diagram: Diagram;
    domainModel: DomainModel;
    proposedConcepts: Concept[];
}

class App extends React.Component<Props, State> {

    topConceptsContainer: React.RefObject<HTMLDivElement>;
    diagramContainer: React.RefObject<HTMLElement>;
    visualVocabularyContainer: React.RefObject<HTMLElement>;

    diagramEditor: React.RefObject<DiagramEditor>;
    visualVocabulary: React.RefObject<VisualVocabulary>;

    state: State = {
        selectedClass: null,
        showDiagram: true,
        diagram: new Diagram("Vehicle Diagram"),
        domainModel: new DomainModel("Vehicle Subject Area"),
        proposedConcepts: [],
    };

    constructor(props: Props) {
        super(props);

        this.topConceptsContainer = React.createRef();
        this.diagramContainer = React.createRef();
        this.visualVocabularyContainer = React.createRef();
        this.diagramEditor = React.createRef();
        this.visualVocabulary = React.createRef();

        // if (window.localStorage.getItem("domainModel")) {
        //     const domainModelJson = JSON.parse(window.localStorage.getItem("domainModel")!)
        //     DomainModel.fromJSON(domainModelJson, this.props.vocabulary);
        // } else {
        //     new DomainModel("Vehicle Subject Area");
        // }

    }

    componentDidUpdate(_prevProps: Readonly<Props>, _prevState: Readonly<State>, _snapshot?: any) {
        console.debug("App updated");
        window.localStorage.setItem("domainModel", JSON.stringify(this.state.domainModel.toJSON()));
    }

    componentDidMount() {
        console.debug("App mounted");

        d3.select(this.diagramContainer.current).append("svg")
            .attr("width", "100%")
            .attr("height", "100%")
            .style("background-color", theme.background);

        document.addEventListener('keydown', (event) => {
            if (event.key === 'Delete') {
                this.diagramEditor.current?.deleteSelectedClasses();
            }
        });

    }

    handleDiagramClassAdded = (diagramClass: DiagramClass) => {
        console.log("Class added: " + diagramClass.modelClass.name);
        console.debug({diagramClass});
        this.state.domainModel.addClass(diagramClass.modelClass);
        this.state.diagram.addClass(diagramClass);
        this.setState({diagram: this.state.diagram});
    }

    handleDiagramClassMoved = (diagramClass: DiagramClass, x: number, y: number) => {
        //console.log(`Class moved to (${x},${y}): ${diagramClass.modelClass.name}`);
        //console.debug({diagramClass});
        diagramClass.x = x;
        diagramClass.y = y;
        this.setState({diagram: this.state.diagram});
    }

    handleDiagramRelationshipAdded = (rel: DiagramRelationship) => {
        console.log("Relationship added: " + rel);
        console.debug({rel});
        this.state.domainModel.addRelationship(rel.modelRelationship);
        this.state.diagram.addRelationship(rel);
        this.setState({diagram: this.state.diagram});
    }

    handleDiagramClassSelected = (diagramClass: DiagramClass | null) => {
        this.selectClass((diagramClass) ? diagramClass?.modelClass : null);
    }

    handleConceptSelected = (concept: Concept) => {
        this.selectConcept(concept);
    }

    render() {
        console.debug("App render");

        const imgStyle = {
            filter: 'invert(1)',
            cursor: 'pointer',
            height: 32,
            width: 32
        }

        const selectedClass = this.state.selectedClass;

        // noinspection BadExpressionStatementJS
        return (
            <div id="app">
                {(this.state.showDiagram) ? (
                    <DiagramEditor
                        ref={this.diagramEditor}
                        onClassSelected={this.handleDiagramClassSelected}
                        gridSize={25}
                        diagram={this.state.diagram}
                        onClassAdded={(dc) => this.handleDiagramClassAdded(dc)}
                        onClassMoved={(dc, x, y) => this.handleDiagramClassMoved(dc, x, y)}
                        onRelationshipAdded={(dr) => this.handleDiagramRelationshipAdded(dr)}
                    />
                ) : (
                    <VisualVocabulary
                        ref={this.visualVocabulary}
                        vocabulary={this.props.vocabulary}
                        onConceptSelected={this.handleConceptSelected}/>
                )}

                <div id="domain">
                    <h1>
                        <span>Knowledge Management (Domain) - Ontology-Driven Conceptual Modelling (Subject Area)&nbsp;</span>
                        <img src="src/assets/graph.png" alt="graph" style={imgStyle} onClick={() => this.toggleView()}/>
                    </h1>
                    <h2>Concepts</h2>
                    <div id="topConcepts" ref={this.topConceptsContainer}>
                        {this.props.vocabulary.getConcepts().map((concept) => (
                            <span key={concept.uri} className="proposed"
                                  onClick={() => this.dropConceptClass(concept.uri)}>
                        {concept.prefLabel}
                            </span>
                        ))}
                        {this.state.proposedConcepts.map((concept) => (
                            <span key={concept.uri} className="proposed">{concept.prefLabel}
                                <button data-concept-id={concept.uri} onClick={() => this.addConcept(concept)}
                                >v</button>
                            </span>
                        ))}
                    </div>
                </div>
                <div id="propertiesContainer">
                    <Properties
                        modelClass={selectedClass}
                        addProperty={(prop: string) => this.addClassProperty(selectedClass!, prop)}
                        addDefinition={(def: {
                            definition: string,
                            proposedRelationships: any
                        }) => this.setClassDefinition(selectedClass!, def)}
                        addRelationship={(rel: string) => this.addClassRelationship(selectedClass!, rel)}
                    />
                </div>
                <Chat
                    onAdvice={(advice) => this.handleAdvice(advice)}
                    vocabulary={this.props.vocabulary}
                    model={this.state.domainModel}
                />
            </div>
        );
    }

    addConcept(concept: Concept) {
        console.log("Adding concept: " + concept.prefLabel);
        console.log({concept});

        this.props.vocabulary.addConcept(concept);
        this.setState({ proposedConcepts: this.state.proposedConcepts.filter(c => c.uri !== concept.uri) });

    }

    handleAdvice(advice: Advice) {
        console.log("Advice received");
        console.log({advice});
        if (advice) {
            if (advice.type == "conceptAdvice") {
                const conceptAdvice = advice as ConceptAdvice;
                conceptAdvice.actions.forEach(action => {
                    if (action.type === "newConcept") {
                        const newConceptAction = action as NewConceptAction;
                        const proposedConcept = newConceptAction.concept;
                        const concept = new Concept(
                            "http://example.org/automobile/" + proposedConcept.prefLabel,
                            proposedConcept.prefLabel,
                            proposedConcept.broaderConcepts.map((broader: string) => "http://example.org/automobile/" + encodeURIComponent(broader)),
                            proposedConcept.narrowerConcepts.map((narrower: string) => "http://example.org/automobile/" + encodeURIComponent(narrower)),
                            proposedConcept.definition);
                        this.state.proposedConcepts.push(concept);
                        this.setState(this.state);
                    } else if (action.type === "updateDefinition") {
                        console.log("Update definition action");
                        const updateDefinitionAction = action;
                        this.props.vocabulary.updateConceptDefinition(updateDefinitionAction.concept.href, updateDefinitionAction.definition);
                        this.setState(this.state);
                    }
                })
            }
        }
    }

    addClassProperty(aClass: ModelClass, prop: string) {
        console.log({aClass, prop});

        aClass.properties.push(prop);

        // remove the proposed property from the list
        aClass.proposedProperties = aClass.proposedProperties.filter(p => p !== prop);

        this.diagramEditor.current?.refreshClasses();
        this.selectClass(aClass);
    }

    setClassDefinition(aClass: ModelClass, def: { definition: string, proposedRelationships: any }) {
        aClass.definition = def.definition;
        aClass.proposedDefinitions = [];
        aClass.proposedRelationships = def.proposedRelationships;
        this.selectClass(aClass);
    }

    addClassRelationship(_aClass: ModelClass, _rel: any) {
        // diagramClass.proposedRelationships = [];
        // diagramClass.relationships.push(rel);
        // classes.push(rel.target);
        // const relClass: any = classes.find(c => c.name === rel.target.name);
        // const newClass = new ModelClass(relClass.type, relClass.name);
        // const diagramClass3 = this.diagramEditor.current?.addDiagramClass({x: 400, y: 500}, newClass);
        // if (diagramClass3) {
        //     this.diagramEditor.current?.classes.push(diagramClass3);
        //     this.diagramEditor.current?.addRelationship(RELATIONSHIP_TYPES.AGGREGATION, this.diagramEditor.current?.classes[0], diagramClass3);
        // }
        // this.selectClass(diagramClass);
    }

    selectClass(aClass: ModelClass | null) {
        console.log("Selected target: " + aClass?.name);
        console.log({aClass});

        this.setState({selectedClass: aClass});
    }

    dropConceptClass(conceptId: string) {
        const concept = this.props.vocabulary.getConcept(conceptId!);
        //addConceptClass(concept);
        this.diagramEditor.current!.dragActive = true;
        this.diagramEditor.current!.dragActiveElement = this.diagramEditor.current!.createClassPlaceholder();
        const modelClass = new ModelClass("Entity", concept.prefLabel);
        modelClass.concept = concept;
        this.diagramEditor.current!.dragActiveModel = modelClass;
    }

    toggleView() {
        this.state.selectedClass = null;
        this.setState({showDiagram: !this.state.showDiagram});
    }

    selectConcept(concept: Concept) {
        console.log("Selected concept: " + concept.uri);
        console.log({concept});

        return;

        // display the concept in #properties
        //$("#properties").empty();
        // $("#selectedClass").html(concept.prefLabel);
        // $("#selectedClassType").text("Concept");
        // $("#selectedClassProperties")
        //     .empty()
        //     .append(`<p>${concept.prefLabel}</p>`);
        // $("#proposedProperties").empty();
        // $("#selectedClassDefinition").text(concept.definition);
        // $("#proposedDefinitions").empty();
        // $("#selectedClassRelationships").empty();
        // concept.broader.forEach((broader: string) => {
        //     $("#selectedClassRelationships").append(`<p>broader ${broader}</p>`);
        // });
        // concept.narrower.forEach((narrower: string) => {
        //     $("#selectedClassRelationships").append(`<p>narrower ${narrower}</p>`);
        // });
    }

}

export {App};

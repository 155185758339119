import React from "react";
import OpenAI, {Message} from "@semantical/services/openai.ts";
import Vocabulary from "@semantical/modules/vocabulary/Vocabulary.ts";
import DomainModel from "@semantical/modules/domain-model/DomainModel.ts";
import chatTypes from "@semantical/components/chat/chatTypes.ts?raw";
import {Advice, TVocabulary} from "./chatTypes.ts";


type Props = {
    onAdvice: (advice: Advice) => void
    vocabulary: Vocabulary
    model: DomainModel
}

type State = {
    messages: Message[]
}

type Intervals = {
    proposeConcepts: Interval
    proposeModel: Interval
}

type Interval = {
    timeout: NodeJS.Timeout | null,
    callback: () => void
}

export default class Chat extends React.Component<Props, State> {

    readonly intervals: Intervals = {
        proposeConcepts: {
            callback: () => (this.isQueryLoading() || this.proposeConcepts()),
            timeout: null
        },
        proposeModel: {
            callback: () => (this.isQueryLoading() || this.proposeModel()),
            timeout: null
        }
    }

    readonly chatbot = new OpenAI('You are a Data expert that give huge importance to semantic. Your only language to answer is through JSON. When a method is called, please consider parameters and provide answer as defined by the method definition.\n' + chatTypes);

    state: State = {
        messages: this.chatbot.messages.slice()
    }

    constructor(props : Props) {
        super(props);
    }

    render() {
        return (
            <div id="chat">
                <form onSubmit={(e) => this.doChatQuery(e)}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <input id="chatQueryInput" type={"text"}/>
                        <img id="chatQueryLoading" src="/src/assets/ripple.gif" alt="loading" width={40} height={40}
                             style={{display: "none", marginLeft: "1em"}}/>
                        <span><input type="checkbox"
                                     onChange={(e) => this.toggleInterval(e, this.intervals.proposeConcepts)}/>Propose Concepts</span>
                        <span><input type="checkbox"
                                     onChange={(e) => this.toggleInterval(e, this.intervals.proposeModel)}/>Propose Model</span>
                    </div>
                </form>
                {this.state.messages.slice().reverse().map((msg, i) => (
                    <div key={i} className={msg.role + "Role"}>{msg.content}</div>
                ))}
            </div>
        )
    }

    toggleInterval = (e: React.ChangeEvent<HTMLInputElement>, interval: Interval) => {
        if (e.target.checked) {
            interval.timeout = setInterval(interval.callback, 10000);
            interval.callback();
        } else {
            if (interval.timeout) {
                clearInterval(interval.timeout);
                interval.timeout = null;
            }
        }
    }

    proposeModel() {
        const params = [this.props.model.toJSON(), 1];
        const query = 'AdviceService.getModelAdvice(' + params.map((p) => JSON.stringify(p)).join(",") + ')';
        this.showQueryLoading();
        this.chatbot.doChat(query, (response) => this.handleResponse(response));
    }

    proposeConcepts() {
        // build a string like "I'm within the Vehicle subject area of the Automobile Domain. The Subject Area contains the following Concepts: Vehicle, Car, Sedan, SUV, Electric Vehicle and Hybrid Car. What other concepts would you proposed to add to this subject area?"
        const subjectArea = "Ontology-Drive Conceptual Modelling";
        const domain = "Knowledge Management";
        const query = this.adviceService.getConceptAdvice(domain, subjectArea, this.props.vocabulary.toJSON(), 1);
        this.showQueryLoading();
        this.chatbot.doChat(query, (response) => this.handleResponse(response));
    }

    adviceService = {
        getConceptAdvice: (
            domain: string,
            subjectArea: string,
            vocabulary: TVocabulary,
            maxNumberOfConcepts: number
        ) => {
            const params = [domain, subjectArea, vocabulary, maxNumberOfConcepts]
            return 'AdviceService.getConceptAdvice(' + params.map((p) => JSON.stringify(p)).join(",") + ')';
        },
    }

    showQueryLoading() {
        const loading = document.getElementById("chatQueryLoading") as HTMLImageElement;
        loading.style.display = "inline";
    }

    isQueryLoading() {
        const loading = document.getElementById("chatQueryLoading") as HTMLImageElement;
        return loading.style.display === "inline";
    }

    hideQueryLoading() {
        const loading = document.getElementById("chatQueryLoading") as HTMLImageElement;
        loading.style.display = "none";
    }

    doChatQuery(e : React.FormEvent<HTMLFormElement> ) {
        e.preventDefault();
        const input = document.getElementById("chatQueryInput") as HTMLInputElement;
        const query = input.value;
        input.value = "";
        this.showQueryLoading();
        this.chatbot.doChat(query, (response) => this.handleResponse(response));
    }

    handleResponse(response: string) {
        this.hideQueryLoading();
        console.log("chatbot response: " + response);
        this.props.onAdvice(extractJSONFromMarkdown(response) as Advice);
        this.setState({messages: this.chatbot.messages.slice()});
    }
}

function extractJSONFromMarkdown(mdContent: string): any {
    try {
        return JSON.parse(mdContent);
    } catch (error) {
        // Regular expression to find JSON block inside triple backticks
        const regex = /```json\n([\s\S]*?)\n```/;
        const match = mdContent.match(regex);

        if (match && match[1]) {
            try {
                // Parse the JSON data found
                return JSON.parse(match[1]);
            } catch (error) {
                console.error("Failed to parse JSON:", error);
                return null;
            }
        } else {
            console.log("No JSON data found in the Markdown content.");
            return null;
        }
    }
}

export {Chat};
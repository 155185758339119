import ModelClass from "@semantical/modules/domain-model/ModelClass.ts";
import {TModelRelationship} from "@semantical/components/chat";

export type RELATIONSHIP_TYPE = "COMPOSITION" | "AGGREGATION" | "GENERALIZATION";

type RelationshipEnd = {
    role?: string,
    cardinality?: string,
    modelClass: ModelClass
}

export default class ModelRelationship {
    name : string;
    sourceEnd: RelationshipEnd;
    targetEnd: RelationshipEnd;
    type: RELATIONSHIP_TYPE;

    constructor(name: string, type: RELATIONSHIP_TYPE, sourceEnd: RelationshipEnd, targetEnd: RelationshipEnd) {
        this.name = name;
        this.type = type;
        this.sourceEnd = sourceEnd;
        this.targetEnd = targetEnd;
    }

    toJSON(): TModelRelationship {
        return {
            name: this.name,
            type: this.type,
            sourceEnd: {
                role: this.sourceEnd.role,
                cardinality: this.sourceEnd.cardinality,
                modelClassName: this.sourceEnd.modelClass.name
            },
            targetEnd: {
                role: this.targetEnd.role,
                cardinality: this.targetEnd.cardinality,
                modelClassName: this.targetEnd.modelClass.name
            }
        }
    }

    static fromJSON(json: any, vocabulary: any) {
        return new ModelRelationship(json.name, json.type, {
            role: json.sourceEnd.role,
            cardinality: json.sourceEnd.cardinality,
            modelClass: vocabulary
        }, {
            role: json.targetEnd.role,
            cardinality: json.targetEnd.cardinality,
            modelClass: vocabulary
        });
    }
};

export {ModelRelationship};
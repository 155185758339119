import ModelClass from "@semantical/modules/domain-model/ModelClass.ts";

export default class DiagramClass {
    x: number;
    y: number;
    width: number;
    height: number;
    modelClass: ModelClass;

    constructor(modelClass: ModelClass, x: number, y: number, width: number, height: number) {
        this.modelClass = modelClass;
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
    }

    toJSON() {
        return {
            x: this.x,
            y: this.y,
            width: this.width,
            height: this.height,
            modelClass: {
                href: this.modelClass.name
            }
        }
    }

}
// add a circle using a svg path
import * as d3 from "d3";
import {Selection} from "d3";
import theme from "@semantical/theme.ts";
import {
    DiagramRelationshipElement,
    relationshipMarkers
} from "@semantical/components/diagram-editor/DiagramRelationshipElement.tsx";
import DiagramClassElement from "@semantical/components/diagram-editor/DiagramClassElement.tsx";
import Diagram from "@semantical/components/diagram-editor/DiagramEditor.tsx";
import React from "react";
import DiagramClass from "@semantical/modules/diagram/DiagramClass.ts";
import {RELATIONSHIP_TYPE} from "@semantical/modules/domain-model/ModelRelationship.ts";

type TooltipDefinition = {
    appendTo: (svg: Selection<SVGGElement, any, any, any>) => Selection<SVGPathElement, any, any, any>,
    relationshipType: RELATIONSHIP_TYPE
}

const svgTooltipDefinitions : TooltipDefinition[] = [
    {
        appendTo(svg: d3.Selection<SVGGElement, any, any, any>): d3.Selection<SVGPathElement, any, any, any> {
            return svg.append("path")
                .attr("id", "tooltip")
                .attr("fill", "white")
                .attr("d", "M7,0m7,0a7,7 0 1,0 -14,0a7,7 0 1,0 14,0")
                .attr("stroke", "white")
                .attr("stroke-width", 1)
                .attr("style", "cursor: pointer;");
        },
        relationshipType: "AGGREGATION"
    },
    {
        appendTo(svg: Selection<SVGGElement, any, any, any>): Selection<SVGPathElement, any, any, any> {
            return svg.append("path")
                .attr("id", "tooltip2")
                .attr("d", "M0,0L7,7L14,0L7,-7Z")
                .attr("fill", theme.lineDiamondFill)
                .attr("stroke", "white")
                .attr("transform", "translate(0,0)")
                .attr("style", "cursor: pointer;");
        },
        relationshipType: "COMPOSITION"

    },
    {
        appendTo(svg: Selection<SVGGElement, any, any, any>): Selection<SVGPathElement, any, any, any> {
            return svg.append("path")
                .attr("id", "tooltip3")
                .attr("d", "M0,0L14,-7L14,7L0,0Z")
                .attr("fill", theme.lineDiamondFill)
                .attr("stroke", "white")
                .attr("transform", "translate(0,0)")
                .style("display", "cursor: pointer;");
        },
        relationshipType: "GENERALIZATION"
    }
];

type Props = {
    diagram: Diagram,
    addRelationship: (relationshipType: RELATIONSHIP_TYPE, source: DiagramClass, target: DiagramClass) => void,
    forClass: DiagramClassElement | null
};

type State = {};

export default class Tooltips extends React.Component<Props, State> {

    svg = React.createRef<SVGGElement>();

    showTooltips(diagramClass: DiagramClassElement) {
        const pos = diagramClass.getPosition();

        // display tooltip
        d3.select("#tooltip")
            .style("display", "block")
            .attr("transform", `translate(${pos.x + 220},${pos.y + 5})`);
        // display tooltip
        d3.select("#tooltip2")
            .style("display", "block")
            .attr("transform", `translate(${pos.x + 220},${pos.y + 25})`);
        // display tooltip
        d3.select("#tooltip3")
            .style("display", "block")
            .attr("transform", `translate(${pos.x + 220},${pos.y + 45})`);
    }

    hideTooltips() {
        d3.select("#tooltip").style("display", "none");
        d3.select("#tooltip2").style("display", "none");
        d3.select("#tooltip3").style("display", "none");
    }

    constructor(props: Props) {
        super(props);

        this.svg = React.createRef<SVGGElement>();
    }

    componentDidMount() {
        console.debug("ToolTips mounted");
        this.createTooltips();
    }

    render() {
        console.debug("render Tooltips");
        console.debug({forClass: this.props.forClass});
        if (this.props.forClass) {
            const pos = this.props.forClass.getPosition();
            return (
                <g ref={this.svg} className="tooltips" transform={`translate(${pos.x + 220}, ${pos.y})`}>
                </g>
            );
        }
    }

    handleDragStart = (_e: d3.D3DragEvent<any, any, any>, tooltipDefinition: TooltipDefinition) => {
        d3.select(this.props.diagram.svgViewport.current).append("path")
            .attr("id", "tooltipLine")
            .attr("stroke", theme.line)
            .attr("stroke-width", 1)
            .attr("marker-start", `url(#${relationshipMarkers.get(tooltipDefinition.relationshipType)})`);
    }

    handleDrag = ({sourceEvent}: d3.D3DragEvent<any, any, any>) => {
        if (!this.props.forClass)
            return;

        const diagram = this.props.diagram;
        const targetClass = diagram.findClassByTarget(sourceEvent.target);
        if (targetClass) {
            //diagramClass.svgClass.attr("fill", "red");
            d3.select("#tooltipLine")
                .attr("d", DiagramRelationshipElement.GetPath(this.props.forClass.props.diagramClass, targetClass));
        } else {
            const pos = this.props.forClass!.getPosition();
            const [x,y] = d3.pointer(sourceEvent, this.props.diagram.svgClasses.current);
            // create a straight path from the selected target to the cursor
            const straightLinePath = d3.line()([
                [pos.x + 200,
                    pos.y + 100],
                [x, y]
            ]);
            // console.debug(straightLinePath);
            // console.debug();

            d3.select("#tooltipLine")
                .attr("d", straightLinePath);
        }
    }



    handleDragEnd = ({sourceEvent}: d3.D3DragEvent<any, any, any>, tooltipDefinition: TooltipDefinition) => {
        // remove the line
        const diagram = this.props.diagram;
        d3.select("#tooltipLine").remove();
        const targetClass = diagram.findClassByTarget(sourceEvent.target);
        if (targetClass) {
            //d3.select(target.svgClass.current).attr("fill", "black");
            this.props.addRelationship(tooltipDefinition.relationshipType, diagram.state.selectedClass!.props.diagramClass, targetClass);
        }
    }

    createTooltips() {

        let dy = 5;

        svgTooltipDefinitions.forEach((tooltipDefinition) => {

            const svgTooltip = tooltipDefinition.appendTo(d3.select(this.svg.current!))
                .attr("transform", `translate(0,${dy})`);

            dy += 20;

            svgTooltip
                .call(d3.drag<SVGPathElement, unknown>()
                    .on("start", (e) => this.handleDragStart(e, tooltipDefinition))
                    .on("drag", (e) => this.handleDrag(e))
                    .on("end", (e) => this.handleDragEnd(e, tooltipDefinition)));

            return svgTooltip;
        });

    }
}



import DiagramClass from "./DiagramClass.ts";
import DiagramRelationship from "@semantical/modules/diagram/DiagramRelationship.ts";

export default class Diagram {
    name: string;
    classes: DiagramClass[] = [];
    relationships: DiagramRelationship[] = [];

    constructor(name: string) {
        this.name = name;
    }

    toJSON() {
        return {
            name: this.name,
            classes: this.classes.map((aClass) => aClass.toJSON()),
            relationships: this.relationships.map((relationship) => relationship.toJSON())
        }

    }

    addClass(aClass: DiagramClass) {
        this.classes.push(aClass);
    }

    addRelationship(diagramRelationship: DiagramRelationship) {
        this.relationships.push(diagramRelationship);
    }

}
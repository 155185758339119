import React from 'react';
import { ModelClass } from '@semantical/modules/domain-model';

type State = {
}

type Props = {
    modelClass: ModelClass | null
    addProperty: (prop: string) => void
    addDefinition: (def: { definition: string, proposedRelationships: any }) => void
    addRelationship: (rel: string) => void
}

class Properties extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    handleAddProperty = (prop: string) => {
        this.props.addProperty(prop);
    }

    handleAddDefinition = (def: { definition: string, proposedRelationships: any }) => {
        this.props.addDefinition(def);
    }

    handleAddRelationship = (rel: string) => {
        this.props.addRelationship(rel);
    }

    render() {
        const modelClass: ModelClass = this.props.modelClass!;
        if (modelClass) return (
            <div id="properties">
                <h2 id="selectedClass">{modelClass?.name}</h2>
                <h3 id="selectedClassType">{modelClass?.type}</h3>
                <h3>Definition <img alt="alt" src="https://cdn-icons-png.freepik.com/512/4020/4020112.png"/></h3>
                <p id="selectedClassDefinition">{(modelClass.concept) ? modelClass.concept.definition : modelClass.definition}</p>
                <div id="proposedDefinitions">
                    {modelClass.proposedDefinitions.map((def: any) => (
                        <p className="proposed">
                            <span>{def.definition} ({def.source})</span>
                            <button className="btn btn-primary btn-sm" onClick={() => this.handleAddDefinition(def)}>v</button>
                        </p>
                    ))}
                </div>
                <h3>Attribute Properties</h3>
                <div id="selectedClassProperties">
                    {modelClass.properties.map((prop, index) => (
                        <li key={index}>{prop}</li>
                    ))}
                </div>
                <div id="proposedProperties">
                    {modelClass.proposedProperties.map((prop) => (
                        <p className="proposed">
                            <span>{prop}</span>
                            <button className="btn btn-primary btn-sm" onClick={() => this.handleAddProperty(prop)}>v</button>
                        </p>
                    ))}
                </div>
                <h3>Relationships</h3>
                <div id="selectedClassRelationships">
                    {modelClass.relationships.map((rel, index) => (
                        <li key={index}>{rel.name} {rel.targetEnd.modelClass.name} ({rel.targetEnd.cardinality})</li>
                    ))}
                </div>
                <div id="proposedRelationships">
                    {modelClass.proposedRelationships.map((rel: any) => (
                        <p className="proposed">
                            <span>{rel.role} {rel.class.name} ({rel.cardinality})</span>
                            <button className="btn btn-primary btn-sm" onClick={() => this.handleAddRelationship(rel)}>v</button>
                        </p>
                    ))}
                </div>
            </div>
        ); else return (
            <div id="properties"><h2>Please select a Class...</h2></div>
        );
    }
}

export default Properties;